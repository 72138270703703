<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-0">
            <feather-icon
              icon="UserIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Profile Photos & Videos
            </h4>
          </div>
        </b-col>
      </b-row>
      <hr>
      <!-- IMAGE -->
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <h4 class="mb-0 ml-75">
              Photo
            </h4>
            <h5 class="mb-0 ml-75">
              <b-badge
                variant="warning"
                class="badge-glow;"
              >
                Max photo 4
              </b-badge>
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-overlay
        :show="loadingMedia"
        rounded="sm"
      >
        <b-row>
          <b-col>
            <VueFileAgent
              ref="vueFileAgentImage"
              v-model="image"
              class="vuefileagent-style"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'image/*'"
              :max-files="4"
              :help-text="'Choose images or video files'"
              :error-text="{
                type: 'Invalid file type. Only images or zip Allowed',
                size: 'Files should not exceed 10MB in size',
              }"
              @select="filesSelectedImage($event)"
              @beforedelete="onBeforeDeleteImage($event)"
              @delete="fileDeletedImage($event)"
            />
            <b-button
              class="mt-1"
              variant="primary"
              :disabled="!fileRecordsForUploadImage.length"
              @click="uploadFilesImage()"
            >
              Upload {{ fileRecordsForUploadImage.length }} files
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
      <hr>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-2">
            <h4 class="mb-0 ml-75">
              Video
            </h4>
            <h5 class="mb-0 ml-75">
              <b-badge
                variant="warning"
                class="badge-glow;"
              >
                Max video 2
              </b-badge>
            </h5>
          </div>
        </b-col>
      </b-row>
      <b-overlay
        :show="loadingMedia"
        rounded="sm"
      >
        <b-row>
          <b-col>
            <VueFileAgent
              ref="vueFileAgentVideo"
              v-model="video"
              class="vuefileagent-style"
              :multiple="true"
              :deletable="true"
              :meta="true"
              :accept="'video/*'"
              :max-files="2"
              :help-text="'Choose images or video files'"
              :error-text="{
                type: 'Invalid file type. Only images or zip Allowed',
                size: 'Files should not exceed 10MB in size',
              }"
              @select="filesSelectedVideo($event)"
              @beforedelete="onBeforeDeleteVideo($event)"
              @delete="fileDeletedVideo($event)"
            />
            <b-button
              class="mt-1"
              variant="primary"
              :disabled="!fileRecordsForUploadVideo.length"
              @click="uploadFilesVideo()"
            >
              Upload {{ fileRecordsForUploadVideo.length }} files
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
} from 'bootstrap-vue'

import 'vue-file-agent/dist/vue-file-agent.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
// import axios from 'axios'

export default {
  components: {
    BOverlay,
  },

  data() {
    return {
      maxImage: 4,
      loadingMedia: true,
      image: [],
      video: [],
      uploadUrl: '/api/profile/upload_media_file',
      uploadHeaders: {
        'X-Test-Header': 'vue-file-agent',
        Authorization: `Bearer ${useJwt.getToken()}`,
      },
      fileRecordsForUploadImage: [],
      fileRecordsForUploadVideo: [],
    }
  },

  created() {
    this.imageRefresh()
  },

  methods: {
    uploadFilesImage() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgentImage.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUploadImage)
      this.fileRecordsForUploadImage = []
      // console.log(this.fileRecordsForUploadImage)
    },

    uploadFilesVideo() {
      // Using the default uploader. You may use another uploader instead.
      this.$refs.vueFileAgentVideo.upload(this.uploadUrl, this.uploadHeaders, this.fileRecordsForUploadVideo)
      this.fileRecordsForUploadVideo = []
    },

    filesSelectedImage(fileRecordsNewlySelectedImage) {
      const validFileRecordsImage = fileRecordsNewlySelectedImage.filter(fileRecord => !fileRecord.error)
      this.fileRecordsForUploadImage = this.fileRecordsForUploadImage.concat(validFileRecordsImage)
      // console.log(JSON.stringify(fileRecordsNewlySelectedImage))
    },

    filesSelectedVideo(fileRecordsNewlySelectedVideo) {
      const validFileRecordsVideo = fileRecordsNewlySelectedVideo.filter(fileRecord => !fileRecord.error)
      this.fileRecordsForUploadVideo = this.fileRecordsForUploadVideo.concat(validFileRecordsVideo)
      // console.log(JSON.stringify(fileRecordsNewlySelectedVideo))
    },

    onBeforeDeleteImage(fileRecord) {
      const i = this.fileRecordsForUploadImage.indexOf(fileRecord)
      if (i !== -1 && window.confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgentImage.deleteFileRecord(fileRecord) // will trigger 'delete' event
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUploadImage.splice(i, 1)
        const k = this.image.indexOf(fileRecord)
        if (k !== -1) this.image.splice(k, 1)
      } else {
        console.log(fileRecord.media_id)
        const request = {
          media_id: fileRecord.media_id,
        }
        this.$http.post('/api/profile/delete_instructor_media', request)
          .then(() => {
            this.imageRefresh()
          })
          .catch(() => {})
          .then(() => {
            this.loadingMedia = false
          })
      }
    },

    onBeforeDeleteVideo(fileRecord) {
      const i = this.fileRecordsForUploadVideo.indexOf(fileRecord)
      if (i !== -1 && window.confirm('Are you sure you want to delete?')) {
        this.$refs.vueFileAgentVideo.deleteFileRecord(fileRecord) // will trigger 'delete' event
        // queued file, not yet uploaded. Just remove from the arrays
        this.fileRecordsForUploadVideo.splice(i, 1)
        const k = this.video.indexOf(fileRecord)
        if (k !== -1) this.video.splice(k, 1)
      }
    },

    fileDeletedImage(fileRecord) {
      const i = this.fileRecordsForUploadImage.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadImage.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },

    fileDeletedVideo(fileRecord) {
      const i = this.fileRecordsForUploadVideo.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUploadVideo.splice(i, 1)
      } else {
        this.deleteUploadedFile(fileRecord)
      }
    },
    /* SHOW AND UPLOAD PHOTO */
    imageRefresh() {
      this.loadingMedia = true
      this.$http.get('/api/profile/get_instructor_media', {})
        .then(res => {
          if (res.data && Array.isArray(res.data)) {
            // this.fileRecordsForUpload = res.data
            this.image = res.data.filter(item => item.media_type === 'image')
            this.video = res.data.filter(item => item.media_type === 'video')
          } else {
            this.image = []
            this.video = []
          }
        })
        .catch(() => {})
        .then(() => {
          this.loadingMedia = false
        })
    },
    limitExceeded(amount) {
      // console.log(amount)
      return amount < 4
    },
    uploadImageSuccess(formData, index, fileList) {
      this.loadingMedia = true
      const file = fileList[index]
      // console.log(JSON.stringify(index))
      // console.log(JSON.stringify(fileList))
      // console.log(JSON.stringify(file))
      if (file) {
        this.$http.post('/api/profile/upload_instructor_media', file)
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'CompassIcon',
                variant: 'success',
                text: 'File uploaded',
              },
            })
          })
          .then(() => {
            this.imageRefresh()
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'CompassIcon',
                variant: 'danger',
                text: error,
              },
            })
          })
      }
    },

    beforeRemove(index, done, fileList) {
      this.loadingMedia = true
      // console.log('index', index, fileList)
      const media = fileList[index]
      if (!media) {
        return
      }
      const request = {
        media_id: media.media_id,
      }
      this.$http.post('/api/profile/delete_instructor_media', request)
        .then(() => {
          this.imageRefresh()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'warning',
              text: 'File deleted',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
  .custom-hide{
    display: none;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

hr {
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.image-container[data-v-10e59822] {
  width: 100%;
  height: 300px;
}

.preview-image[data-v-10e59822] {
    height: 250px;
    box-sizing: border-box;
}

.show-img[data-v-10e59822] {
    max-height: 215px;
    max-width: 330px;
    display: block;
    vertical-align: middle;
}

.image-list-container .image-list-item[data-v-10e59822] {
    height: 80px;
    width: 80px;
}

.flex-wrap[data-v-10e59822] {
  flex-wrap: nowrap !important;
}

.image-list-container[data-v-10e59822] {
    max-width: 300px;
    min-height: 50px;
}

.image-list-container .image-list-item .show-img[data-v-10e59822] {
    max-width: 55px;
    max-height: 55px;
}

.image-primary[data-v-10e59822] {
    font-size: 18px;
}

.image-icon-edit[data-v-10e59822] {
    height: 18px;
    width: 18px;
    fill: #222;
}

.image-icon-delete[data-v-10e59822] {
    height: 18px;
    width: 18px;
    fill: #222;
}

@media (min-width: 1440px) {
  .grid-block-wrapper .grid-block {
      width: 25%;
      border-radius: 15px;
  }
}

@media (min-width: 992px) {
.grid-block-wrapper .grid-block {
    width: 23%;
  }
}

.vue-file-agent.file-input-wrapper {
  border-radius: 15px;
}
</style>
