<template>
  <div>
    <div class="editor-menu-bar">
      <b-button v-if="editor" @click="toggleBold" :class="{ 'is-active': editor.isActive('bold') }" variant="link" class="p-1">
        <b-icon icon="type-bold"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="toggleItalic" :class="{ 'is-active': editor.isActive('italic') }" variant="link" class="p-1">
        <b-icon icon="type-italic"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="toggleStrike" :class="{ 'is-active': editor.isActive('strike') }" variant="link" class="p-1">
        <b-icon icon="type-strikethrough"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="setParagraph" :class="{ 'is-active': editor.isActive('paragraph') }" variant="link" class="p-1">
        <b-icon icon="paragraph"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="toggleH2" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" variant="link" class="p-1">
        <b-icon icon="type-h2"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="toggleH3" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" variant="link" class="p-1">
        <b-icon icon="type-h3"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="toggleBulletList" :class="{ 'is-active': editor.isActive('bulletList') }" variant="link" class="p-1">
        <b-icon icon="list-ul"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="toggleOrderedList" :class="{ 'is-active': editor.isActive('orderedList') }" variant="link" class="p-1">
        <b-icon icon="list-ol"></b-icon>
      </b-button>
      <b-button v-if="editor" @click="setHorizontalRule" variant="link" class="p-1">
        <b-icon icon="hr"></b-icon>
      </b-button>
    </div>
    <editor-content v-if="editor" :editor="editor" />
  </div>
</template>

<script>
import { EditorContent, Editor } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import { BIcon, BButton } from 'bootstrap-vue'

export default {
  components: {
    EditorContent,
    BIcon,
    BButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      editor: null,
    }
  },
  watch: {
    value(val) {
      if (this.editor && this.editor.getHTML() !== val) {
        this.editor.commands.setContent(val, false)
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
      ],
      content: this.value,
      onUpdate: ({ editor }) => {
        this.$emit('input', editor.getHTML())
      },
    })
  },
  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy()
    }
  },
  methods: {
    toggleBold() {
      this.editor.chain().focus().toggleBold().run()
    },
    toggleItalic() {
      this.editor.chain().focus().toggleItalic().run()
    },
    toggleStrike() {
      this.editor.chain().focus().toggleStrike().run()
    },
    unsetAllMarks() {
      this.editor.chain().focus().unsetAllMarks().run()
    },
    setParagraph() {
      this.editor.chain().focus().setParagraph().run()
    },
    toggleH1() {
      this.editor.chain().focus().toggleHeading({ level: 1 }).run()
    },
    toggleH2() {
      this.editor.chain().focus().toggleHeading({ level: 2 }).run()
    },
    toggleH3() {
      this.editor.chain().focus().toggleHeading({ level: 3 }).run()
    },
    toggleBulletList() {
      this.editor.chain().focus().toggleBulletList().run()
    },
    toggleOrderedList() {
      this.editor.chain().focus().toggleOrderedList().run()
    },
    setHorizontalRule() {
      this.editor.chain().focus().setHorizontalRule().run()
    },
  }
}
</script>

<style>
.ProseMirror {
  padding: 1em;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 200px;
}
.editor-menu-bar {
  margin-bottom: 10px;
}
.editor-menu-bar button {
  margin-right: 5px;
}
.editor-menu-bar button.is-active {
  font-weight: bold;
  background-color: #e2e8f0; /* Цвет фона кнопки при активации */
}
</style>
