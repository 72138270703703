<template>
  <b-overlay
    :show="loadingInfo"
    rounded="sm"
  >
    <b-card>
      <b-row>
        <b-col cols="12">
          <div class="d-flex align-items-center mb-0">
            <feather-icon
              icon="UserIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Profile
            </h4>
            <h4 class="mb-0 ml-75">
              <b-badge
                :variant="profile_status_color"
                class="badge-glow;"
              >
                {{ profile_status }}
              </b-badge>
            </h4>
            <b-button
              v-b-modal.modal-center
              class="more_information"
              variant="outline"
            >
              <b-icon
                icon="question-circle-fill"
              />
              <b-modal
                id="modal-center"
                centered
                hide-footer
                hide-header
              >
                <p
                  class="my-1"
                >
                  1. Confirmation will take up to 3 business days.<br>
                </p>
              </b-modal>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col md="3">
          <b-avatar
            size="220px"
            :src="userInfo.avatar ? 'data:image/jpeg;base64,' + userInfo.avatar : null"
            button
            rounded="lg"
            class="mb-1"
            @click="$refs.refInputEl.$el.click()"
          />
          <b-form-file
            ref="refInputEl"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="handleImage"
          />
        </b-col>
        <b-col md="5">
          <b-card>
            <b-card-text>
              1. Use a friendly, smiling headshot
            </b-card-text>
            <b-card-text>
              2. Consider using your photo from LinkedIn
            </b-card-text>
            <b-card-text>
              3. Avoid awkward cropping, angles, skate shots, and filters—just show your face
            </b-card-text>
            <b-card-text>
              4. Solo shots only—there shouldn't be anyone else in your picture
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <validation-observer
        ref="registerForm"
        #default="{invalid}"
      >
        <b-form
          class="auth-register-form mt-2"
          @submit.prevent="register"
        >
          <b-row>
            <!-- Birthday -->
            <b-col md="6">
              <b-form-group
                label="Birthday"
                label-for="birth_date"
              >
                <b-form-datepicker
                  id="birth_date"
                  v-model="profileData.birth_date"
                  placeholder="Choose your birthday"
                  locale="en-US"
                  :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                />
              </b-form-group>
            </b-col>
            <!-- Birthday -->

            <!-- Start Skate-->
            <b-col md="6">
              <b-form-group
                label="When did you start skateboarding?"
                label-for="start_skate"
              >
                <datepicker
                  id="start_skate"
                  v-model="profileData.start_skate"
                  format="yyyy"
                  class="datepicker-fix"
                  placeholder="year"
                  :bootstrap-styling="true"
                  minimum-view="year"
                  name="datepicker"
                />
              </b-form-group>
            </b-col>
            <!-- Start Skate -->
          </b-row>
          <b-row>
            <!-- Instagram-->
            <b-col md="6">
              <b-form-group
                label="Instagram (http://instagram.com/username)"
                label-for="instagram"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Instagram"
                  vid="instagram"
                  rules="instagram"
                >
                  <b-form-input
                    id="instagram"
                    v-model="profileData.instagram"
                    placeholder="http://instagram.com/username"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Instagram -->

            <!-- Facebook -->
            <b-col md="6">
              <b-form-group
                label="Facebook (http://facebook.com/username)"
                label-for="facebook"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Facebook"
                  vid="facebook"
                  rules="facebook"
                >
                  <b-form-input
                    id="facebook"
                    v-model="profileData.facebook"
                    placeholder="http://facebook.com/username"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Facebook -->
          </b-row>
          <b-row>
            <!-- Skills -->
            <b-col md="12">
              <b-form-group
                label="Your Level of Experience"
                label-for="skills"
              >
                <v-select
                  id="skills"
                  v-model="profileData.skills_id"
                  label="name"
                  :options="skillsOption"
                  :reduce="skills => skills.id"
                />
              </b-form-group>
            </b-col>
            <!-- Skills -->
          </b-row>
          <b-row>
            <!-- Сourse -->
            <b-col md="12">
              <b-form-group
                label="About me"
                label-for="about"
              >
                <TiptapEditor
                  id="about"
                  v-model="profileData.about"
                  placeholder="Enter something..."
                />
              </b-form-group>
            </b-col>
            <!-- Сourse -->
          </b-row>
          <b-row>
            <!-- What parks? -->
            <b-col md="12">
              <b-form-group
                label="What parks/areas are closest for you to give lessons?"
                label-for="parks"
              >
                <TiptapEditor
                  id="parks"
                  v-model="profileData.parks"
                  placeholder="Enter something..."
                />
              </b-form-group>
            </b-col>
            <!-- What parks? -->
          </b-row>
          <b-row>
            <!-- What tricks? -->
            <b-col md="12">
              <b-form-group
                label="What Types Of Tricks And Skating Can You Teach"
                label-for="tricks"
              >
                <TiptapEditor
                  id="tricks"
                  v-model="profileData.tricks"
                  placeholder="Enter something..."
                />
              </b-form-group>
            </b-col>
            <!-- What parks? -->
          </b-row>
          <!-- <b-row class="mb-1" >
            <b-col>
              <b-form-group
                label="What Types of Tricks and Skating Can You Teach?"
                label-for="tricks"
              >
                <b-form-tags
                  id="tricks"
                  v-model="profileData.tricks"
                  placeholder="Add tricks"
                />
              </b-form-group>
            </b-col>
          </b-row> -->
          <b-row>
            <!-- Education -->
            <b-col md="12">
              <b-form-group
                label="Your Highest Level of Education"
                label-for="education_level_id"
              >
                <v-select
                  id="education_level_id"
                  v-model="profileData.education_id"
                  label="name"
                  :options="educationOption"
                  :reduce="education => education.id"
                />
              </b-form-group>
            </b-col>
            <!-- Education -->
          </b-row>
          <b-row>
            <!-- Сourse -->
            <b-col md="12">
              <b-form-group
                label="What Will Students Get From a Course With You?"
                label-for="сourse"
              >
                <TiptapEditor
                  id="сourse"
                  v-model="profileData.courses"
                  placeholder="Enter something..."
                />
              </b-form-group>
            </b-col>
            <!-- Сourse -->
          </b-row>
          <b-button
            variant="primary"
            class="mt-0 mr-1"
            :disabled="invalid"
            @click="save_profile"
          >
            Save changes
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BAvatar,
  BBadge,
  BOverlay,
  // BAvatar,
  /*
  BCardText,
  BLink,
  BAvatar,
  */
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, facebook, instagram } from '@validations'
import Datepicker from 'vuejs-datepicker'

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import TiptapEditor from './TiptapEditor.vue'

export default {
  components: {
    TiptapEditor,
    ValidationProvider,
    ValidationObserver,
    Datepicker,
    BBadge,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BOverlay,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      skillsOption: [],
      educationOption: [],
      userInfo: {
        rating: 0,
        full_name: null,
      },
      option: [],
      required,
      facebook,
      instagram,
      loadingInfo: true,
    }
  },

  computed: {
    profile_status() {
      switch (this.profileData.instructor_state_code) {
        case 'CONFIRM':
          return 'Confirmed'
        case 'CHANGE_REQUEST':
          return 'Awaiting confirmation'
        case 'NEW':
          return 'Fill out the profile'
        case 'REJECTED':
          return 'Profile deleted'
        default:
          return 'None'
      }
    },

    profile_status_color() {
      switch (this.profileData.instructor_state_code) {
        case 'CONFIRM':
          return 'success'
        case 'CHANGE_REQUEST':
          return 'warning'
        case 'NEW':
          return 'primary'
        case 'REJECTED':
          return 'danger'
        default:
          return 'None'
      }
    },
  },

  created() {
    this.data_refresh_profile()
    this.get_instructor_skills()
    this.get_instructor_educations()
  },

  beforeCreate() {
    // ? console.log('data')
    this.$http.get('/api/profile/get_instructor_info')
      .then(res => {
        /*
        const data = JSON.parse(res.data)
        console.log(data)
        */
        this.userInfo = res.data
      })
  },
  methods: {
    get_instructor_educations() {
      this.$http.get('/api/profile/get_instructor_educations', {})
        .then(res => {
          // const data = JSON.parse(res.data)
          // console.log(res.data)
          this.educationOption = res.data
        })
    },
    get_instructor_skills() {
      this.$http.get('/api/profile/get_instructor_skills', {})
        .then(res => {
          // const data = JSON.parse(res.data)
          // console.log(res.data)
          this.skillsOption = res.data
        })
    },
    save_profile() {
      this.$http.post('/api/profile/save_profile', this.profileData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'warning',
              text: 'Profile updated, wait for confirmation',
            },
          })
          this.data_refresh_profile()
        })
    },

    data_refresh_profile() {
      this.loadingInfo = true
      this.$http.get('/api/profile/get_profile', {})
        .then(res => {
          // const data = JSON.parse(res.data)
          // console.log(res.data)
          this.profileData = res.data
        })
        .catch(() => {})
        .then(() => {
          this.loadingInfo = false
        })
    },

    handleImage(e) {
      const inputImageRenderer = e.target.files[0]
      if (!inputImageRenderer) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'success',
            text: 'No file chosen',
          },
        })
        return
      }
      if (inputImageRenderer.size > 800 * 1024) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'danger',
            text: 'File too big (> 800KB)',
          },
        })
        return
      }

      this.createBase64Image(inputImageRenderer)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CompassIcon',
          variant: 'success',
          text: 'File uploaded',
        },
      })
    },

    createBase64Image(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = /,(.+)/.exec(reader.result)[1]
        // console.log(`file to base64 result: ${base64String}`)
        if (base64String) {
          this.$http.post('/api/profile/upload_instructor_avatar', base64String)
        }
      }
      // reader.onerror = error => {
      // console.log('Error: ', error)
      // }
    },

    reset_avatar() {
      this.$http.post('/api/profile/clear_instructor_avatar')
    },
  },
}
</script>

<style lang="scss">
  .custom-hide{
    display: none;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';

hr {
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.datepicker-fix .input-group > .form-control{
  width: 100%;
}

.datepicker-fix > div > .form-control[readonly]{
  background-color: #fff;
  text-align: center;
}
</style>
