<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Profile</span>
        </template>
        <profile-info
          v-if="data"
          :profile-data="data"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="VideoIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">Profile Photos & Videos</span>
        </template>
        <profile-media
          v-if="data"
          :media-data="data"
        />
      </b-tab>
      <!-- <b-button
        variant="primary"
        class="mt-0 mr-1"
        @click="save_profile"
      >
        Save changes
      </b-button> -->
    </b-tabs>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ProfileInfo from './ProfileInfo.vue'
import ProfileMedia from './ProfileMedia.vue'

export default {
  components: {
    ProfileInfo,
    ProfileMedia,
  },

  data() {
    return {
      data: {},
    }
  },

  methods: {
    handleImage(e) {
      const inputImageRenderer = e.target.files[0]
      if (!inputImageRenderer) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'success',
            text: 'No file chosen',
          },
        })
        return
      }
      if (inputImageRenderer.size > 800 * 1024) {
        e.preventDefault()
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            icon: 'CompassIcon',
            variant: 'danger',
            text: 'File too big (> 800KB)',
          },
        })
        return
      }

      this.createBase64Image(inputImageRenderer)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Success',
          icon: 'CompassIcon',
          variant: 'success',
          text: 'File uploaded',
        },
      })
    },

    /* save_profile() {
      this.$http.post('/api/profile/save_profile', this.data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success',
              icon: 'CompassIcon',
              variant: 'warning',
              text: 'Profile updated, wait for confirmation',
            },
          })
          this.data_refresh_profile()
        })
    }, */
  },
}
</script>

<style lang="scss">
  .custom-hide{
    display: none;
  }
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';

hr {
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}
</style>
